import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import {UntypedFormControl, FormGroupDirective, NgForm, Validators, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { FileUploader, FileSelectDirective } from 'ng2-file-upload';
import { Meta, Title } from '@angular/platform-browser';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { AuthenticationService, TokenPayload } from '../../../services/auth/authentication.service';   
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { NavigationService } from '../../../services/auth/navigation.service';
import { environment } from '../../../../../environments/environment';

 const baseUrl = environment.baseUrl;
// tslint:disable-next-line:prefer-const
let URL =  `/services/addlisting`;
 /** Error when invalid control is dirty, touched, or submitted.  */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const controlTouched = !!(control && (control.dirty || control.touched));
        const controlInvalid = !!(control && control.invalid);
        const parentInvalid = !!(control && control.parent && control.parent.invalid && (control.parent.dirty || control.parent.touched));
        return (controlTouched && (controlInvalid || parentInvalid));
    }
}

@Component({
    selector: 'app-dashboard-add-listings',
    templateUrl: './dashboard-add-listings.component.html',
    styleUrls: ['./dashboard-add-listings.component.scss'],
    standalone: false
})
export class DashboardAddListingsComponent implements OnInit {

    showSpinner: boolean = false;

    addlisingFormGroup: UntypedFormGroup;
    hide = true;
    title: any;
    description: any;
    category: any;
    subcategory: any;
    scategory: any;
    keyword: any;
    email: any;
    address: any;
    city: any;
    state: any;
    zip: any;
    country: any;    
    phone: any;
    website: any;
    facebook: any;
    twitter: any;
    linkedin: any;
    price: any;
    uploader:FileUploader;
    CUST_ID : any;

    baseUrl: any;
    // addmore: boolean;
    // newinput: boolean;
 
    errorMessage: any;
    listcategory: any;
    listsubcategory: any;
    //errorMessageForm: any;
    //errorStatus=false;
    isUploaderLength: number;

    allowedMimeType = ['application/pdf', 'image/jpeg','image/png','text/plain'];
    maxFileSize = 1000000; //100000
    document: string;

    previousRoute: string;
    
    constructor(
        meta: Meta, title: Title,
        private fb: UntypedFormBuilder,
        private auth: AuthenticationService, 
        private router: Router,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        private routingState: NavigationService,
        public local: LocalStorageService,
    ) { 
        this.uploader = new FileUploader({
            url: URL,
            allowedMimeType: this.allowedMimeType,
            // headers: [{name:'Accept', value:'application/json'}],
            autoUpload: false,
            maxFileSize: this.maxFileSize,
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise( (resolve, reject) => {
                  resolve({
                    name: item._file.name,
                    length: item._file.size,
                    contentType: item._file.type,
                    date: new Date()
                  });
                });
              }
           
              

        });
        // VALIDATE - FILE TYPE AND FILE SIZE
        this.uploader.onWhenAddingFileFailed = (item, filter, options) => this.onWhenAddingFileFailed(item, filter, options);

        
        // VALIDATE - DUPLICATE FILES        
        this.uploader.onAfterAddingFile = f => {
            var i = 0;
            while (i++ < this.uploader.queue.length) {
                var ii = i;
                while (ii < this.uploader.queue.length) 
                {
                    if (this.uploader.queue[i - 1]._file['name'] == this.uploader.queue[ii]._file['name']) 
                    {
                        this.errorMessage = this.uploader.queue[i - 1]._file['name'] + ` File with the same name is already exist !`;
                        this.uploader.removeFromQueue(this.uploader.queue[ii]);   
                    } 
                    else 
                    {
                        ii++;
                        this.errorMessage = '';
                    }
                }
            }
            this.getUploaderLengthFun(); 
        };
        
        this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
            this.errorMessage = '';
        };
        
    }   
        
    getUploaderLengthFun()
    {
        this.isUploaderLength = this.uploader.queue.length;
    }
    onWhenAddingFileFailed(item: any, filter: any, options: any) {
        switch (filter.name) {

            case 'fileSize':
                var itemSize='';
                if (item.size == 0) 
                {
                    itemSize = '0 Bytes';
                }                
                const k = 1024,
                dm = 2,
                sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                i = Math.floor(Math.log(item.size) / Math.log(k));
                itemSize = parseFloat((item.size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
                this.errorMessage = `Maximum upload size exceeded (${(itemSize)} of ${this.maxFileSize/1000/1000} MB allowed)!`;
                break;

            case 'mimeType':
                const allowedTypes = this.allowedMimeType.join();
                this.errorMessage = ` The specified file type could not be uploaded. We only accept PDF, TXT, JPG, PNG or JPEG !`;
                break;
            default:
                this.errorMessage = '';                
        }
    }
    
  

    ngOnInit(): void {

        this.setUserRegisterFormFun();
        this.getUploaderLengthFun();

          this.CUST_ID = this.local.get("CUST_ID");
          this.auth.getcategory().subscribe(
          
                data => {
                //    this.listcategory = data;
                    this.listcategory = data.map((item) => item.category)
                //  console.log(optioncategory);
                    console.log(this.listcategory);
                },
                err => {
                    // console.error(err);
                }
            );

          
        
    }
    
    breadcrumb = [
        {
            title: 'Add Listings',
            subTitle: 'Dashboard'
        }
    ]


    setUserRegisterFormFun()
    { 
    this.showSpinner = true;
    this.addlisingFormGroup = this.fb.group({            
        title: ['', Validators.required],
        description: ['', Validators.required],
        category: ['', Validators.required],
        subcategory: ['', Validators.required],
        keyword: ['', Validators.required],
        address: [''],
        city: ['', Validators.required],
        state: [''],
        zip: ['', Validators.required],
        phone: [''],
        website: [''],
        email: ['', [Validators.required, Validators.email]],
        facebook: [''],
        twitter: [''],
        linkedin: [''],
        price: [''],
        document: [''],
        CUST_ID : [''],
      
    },  ); 

    this.country = "Australia";
      
    // get return url from route parameters or default to '/'
   // this.previousRoute = this.routingState.getPreviousUrl();
    this.showSpinner = false;
    // get return url from route parameters or default to '/'
    // this.previousRoute = this.routingState.getPreviousUrl();    
    }

    public hasError = (controlName: string, errorName: string) =>{
        return this.addlisingFormGroup.controls[controlName].hasError(errorName);
    }

    addlising() 
    { 
        
        const fileUploadData: any = new FormData();
        var files2 = [];
        for (var j = 0; j < this.uploader.queue.length; j++) 
        {               
            files2.push(this.uploader.queue[j]._file);
        }
        for(let i =0; i < files2.length; i++)
        { 
            fileUploadData.append("uploads[]", files2[i], files2[i]['name']);
            
        }
     //   console.log(files2);
    //    console.log(fileUploadData);
         fileUploadData.append('CustDetails', JSON.stringify(this.addlisingFormGroup.value)); 

      //   console.log(fileUploadData);

      
   //  if (this.userRegisterFormGroup.valid) 
  //  {
        // VALIDATE Customer FORM DETAILS
        var regExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regExp.test(this.addlisingFormGroup.value['email']))
        {            
       //     $("#email").focus();
      
        }
        else
        {

           
            this.spinner.show();
            this.auth.addlisings(this.addlisingFormGroup.value,fileUploadData).subscribe(
            (data) => 
            {   
                 if (data.status === true) 
                {    
                 this.spinner.hide();
                 alert("Added listing Sucessfully !");
                    this.toastr.success("Added listing Sucessfully !", 'Done!');
                   
                    // Registered successful so redirect to return url
                  //  this.router.navigateByUrl(this.previousRoute);    
                  this.router.navigate(['my-listings']);                   
                } 
                else 
                {
                  this.spinner.hide();
                    this.toastr.error(data.message, 'Oops!');
                }
                    
            },
            err => {
            // console.error(err);
            });
        }
    }
        
    // Category Select
   // state: any = [];
   multiSelect: any = [];
   stringArray: any = [];
   objectsArray: any = [];
   resetOption: any;
   config:any = {
       displayKey: "name",
       search: true
   };
  // options = ["WA","QLD","NSW","VIC","SA","TAS","NT","ACT","other"];

  liststate = ["WA","QLD","NSW","VIC","SA","TAS","NT","ACT","other"];
   searchChange($event) {
    console.log("test");
       console.log($event);
   }

   selectedcategory(scategory)
   {
    console.log(scategory);
    if(scategory !="undefined" || scategory !=" " )
    {
        this.auth.getsubcategory(scategory).subscribe(
          
            data => {
                this.listsubcategory = data.map((item) => item.sub_category)
                console.log(this.listsubcategory);
            },
            err => {
                // console.error(err);
            }
        );
    }
   }

} 


 