<!-- Start Copyright End -->
<div class="copyrights-area">
    <div class="row align-items-center">
        <div class="col-lg-6 col-sm-6 col-md-6">
            <p>©Copyright 2024 Best Business Directory All Rights Reserved</p>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 text-end">
            <!-- <p>Designed by   <a href="#" target="_blank">  Best Business Directory A </a></p> -->
        </div>
    </div>
</div>
<!-- End Copyright End -->