import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
    selector: 'app-home-demo-one',
    templateUrl: './home-demo-one.component.html',
    styleUrls: ['./home-demo-one.component.scss'],
    standalone: false
})
export class HomeDemoOneComponent implements OnInit {

  constructor(private meta: Meta, title: Title) {

    meta.addTags([
    // tslint:disable-next-line:max-line-length
    { name: 'description', content: 'Business listings for doctors|solicitors| electricians| Carpenters|dentists|accountants |0488701953' }
    ]);
    // title.setTitle('Buy Prescription Glasses Online Latest Fashion frames |  AusSpecs');
    title.setTitle('BEST BUSINESS DIRECTORY| ROCKHAMPTON|YEPPOON|GLADSTONE ');

    

  }

//   CONTACT BEST BUSINESS DIRECTORY| ROCKHAMPTON|YEPPOON|
// Business listings for doctors|solicitors| electricians| Carpenters|dentists|accountants |0488701953


// Menu Tool Bar
// Home     About Us     Categories     Business Locations    Blog    User Panel    Contact Us
// Footer
// About Us         Privacy Policy     Site Map  Contact Us  Site Map 

  ngOnInit(): void {
  }

}
