import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss'],
    standalone: false
})
export class PricingComponent implements OnInit {

	constructor() { }

	ngOnInit(): void {
	}

    pageTitleContent = [
        {
            title: 'Pricing',
             backgroundImage: 'assets/img/about-banner.jpg'
        }
    ]

    monthlyPricing = [
        {
            singlePricingBox: [
                {
                    title: 'Free Plan',
                    features: [
                        {
                            icon: 'bx bx-check',
                            text: 'Business Details'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Opening hours'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Contact details'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Logo'
                        } ,
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Image Gallery'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Keywords'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Business descriptions'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Awards and Accreditations'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Messaging Links'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Link to Social profiles'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Link to YouTube videos'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Link to your apps'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Editorial links'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Deep links'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Unique Selling Points'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Downloadable PDFs'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'FAQs'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Hero image/video'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Feature tiles'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Your brand colour'
                        }  
                    ],
                    price: '$0/month',
                    buttonText: 'Select Plan',
                    buttonLink: '/register'
                },
                {
                    title: 'Bronze Plan',
                    features: [
                        {
                            icon: 'bx bx-check',
                            text: 'Business Details'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Opening hours'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Contact details'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Logo'
                        } ,
                        {
                            icon: 'bx bx-check',
                            text: 'Image Gallery'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Keywords'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Business descriptions'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Awards and Accreditations'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Messaging Links'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Link to Social profiles'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Link to YouTube videos'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Link to your apps'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Editorial links'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Deep links'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Unique Selling Points'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Downloadable PDFs'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'FAQs'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Hero image/video'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Feature tiles'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Your brand colour'
                        } 
                    ],
                    price: '$20/month',
                    buttonText: 'Select Plan',
                    buttonLink: 'register'
                },
                {
                    title: 'Gold Plan',
                    features: [
                        {
                            icon: 'bx bx-check',
                            text: 'Business Details'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Opening hours'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Contact details'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Logo'
                        } ,
                        {
                            icon: 'bx bx-check',
                            text: 'Image Gallery'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Keywords'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Business descriptions'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Awards and Accreditations'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Messaging Links'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Link to Social profiles'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Link to YouTube videos'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Link to your apps'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Editorial links'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Deep links'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Unique Selling Points'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Downloadable PDFs'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'FAQs'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Hero image/video'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Feature tiles'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Your brand colour'
                        } 
                    ],
                    price: '$49/month',
                    buttonText: 'Select Plan',
                    buttonLink: 'register'
                }
            ]
        }
    ]
    yearlyPricing = [
        {
            singlePricingBox: [
                {
                    title: 'Starter Plan',
                    features: [
                        {
                            icon: 'bx bx-check',
                            text: '5 Listing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Non-Featured'
                        },
                        {
                            icon: 'bx bx-check',
                            text: '90 Days Availability'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'One Time Fee'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Multi Team Tasking'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Registration & Ticketing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Features'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Online Booking'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Limited Support'
                        }
                    ],
                    price: '$119.99',
                    buttonText: 'Select Plan',
                    buttonLink: 'listings'
                },
                {
                    title: 'Advance Plan',
                    features: [
                        {
                            icon: 'bx bx-check',
                            text: '5 Listing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Non-Featured'
                        },
                        {
                            icon: 'bx bx-check',
                            text: '90 Days Availability'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'One Time Fee'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Multi Team Tasking'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Registration & Ticketing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Features'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Online Booking'
                        },
                        {
                            icon: 'bx bx-x action-close',
                            text: 'Limited Support'
                        }
                    ],
                    price: '$129.99',
                    buttonText: 'Select Plan',
                    buttonLink: 'listings'
                },
                {
                    title: 'Premium Plan',
                    features: [
                        {
                            icon: 'bx bx-check',
                            text: '5 Listing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Non-Featured'
                        },
                        {
                            icon: 'bx bx-check',
                            text: '90 Days Availability'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'One Time Fee'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Multi Team Tasking'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Registration & Ticketing'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Basic Features'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Online Booking'
                        },
                        {
                            icon: 'bx bx-check',
                            text: 'Limited Support'
                        }
                    ],
                    price: '$139.99',
                    buttonText: 'Select Plan',
                    buttonLink: 'listings'
                }
            ]
        }
    ]

    // Tabs
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }

}