<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitleContent;" style="background-image: url({{Content.backgroundImage}});">
    <div class="container">
        <div class="page-title-content">
            <h2>{{Content.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{Content.title}}</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start FAQ Area -->
<section class="faq-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6" *ngFor="let Item of faqItem;">
                <div class="faq-item">
                    <h3>Q: {{Item.title}}</h3>
                    <p><strong>A:</strong> {{Item.paragraph}}</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FAQ Area -->

<app-footer-style-one></app-footer-style-one>