import { Component, OnInit } from '@angular/core';
import { AuthenticationService, TokenPayload } from '../../services/auth/authentication.service';

@Component({
    selector: 'app-dashboard-sidemenu',
    templateUrl: './dashboard-sidemenu.component.html',
    styleUrls: ['./dashboard-sidemenu.component.scss'],
    standalone: false
})
export class DashboardSidemenuComponent implements OnInit {

    constructor(
        private auth: AuthenticationService,
    ) { }

    ngOnInit(): void {}

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    logout()
    {
        this.auth.logout();
    }   

}