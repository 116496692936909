<app-navbar-style-one></app-navbar-style-one>
<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitleContent;" style="background-image: url({{Content.backgroundImage}});">
    <div class="container">
        <div class="page-title-content">
            <h2>{{Content.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{Content.title}}</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<div class="checkout-area pt-100 pb-70" >
    <div class="container">        
        <div class="row">
            <div class="col-lg-2 col-12">&nbsp;</div>
            <div class="col-lg-8 col-12 register-container bg-white contact-form">


                <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
            

                
                <form [formGroup]="userRegisterFormGroup" class="formlayout" >
                    <div class="checkbox-form">
                        <h3 class="head-register" style="text-align: center;">Register</h3>
                        <div class="row">
              
                            <div class="col-md-12 fieldlayout">
                                <div class="checkout-form-list">
                                    <mat-form-field  appearance="outline" >
                                        <input class="matformfield" matInput formControlName="f_name" id="f_name" [(ngModel)]="f_name" placeholder="First Name" required>
                                       
                                        <mat-error class="matformfielderror" *ngIf="hasError('f_name', 'required')">First Name is required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-12 fieldlayout">
                                <div class="checkout-form-list">
                                    <mat-form-field  appearance="outline">
                                        <input class="matformfield" matInput formControlName="l_name" id="l_name" [(ngModel)]="l_name" placeholder="Last Name"  required>
                                     
                                        <mat-error class="matformfielderror" *ngIf="hasError('l_name', 'required')">Last Name is required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-12 fieldlayout">
                                <div class="checkout-form-list">
                                    <mat-form-field appearance="outline">
                                        <input class="matformfield" matInput formControlName="phone" id="phone" [(ngModel)]="phone" placeholder="Phone Number"  (keypress)="numberOnly($event)">
                                        <mat-error class="matformfielderror" *ngIf="userRegisterFormGroup.hasError('error')">Either Phone Number or Mobile Number required</mat-error>
                              
                                    </mat-form-field>
                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                                <div class="checkout-form-list">
                                    <mat-form-field  class="mat-input-width">
                                        <input matInput formControlName="mobile" id="mobile" [(ngModel)]="mobile" placeholder="Mobile"   (keypress)="numberOnly($event)"> 
                                        <mat-error *ngIf="userRegisterFormGroup.hasError('error')">Either Phone Number or Mobile Number required</mat-error>
                                    
                                    </mat-form-field>
                                </div>
                            </div> -->
                            <div class="col-md-12 fieldlayout">
                                <div class="checkout-form-list">
                                    <mat-form-field appearance="outline">
                                        <input class="matformfield" matInput formControlName="address1" id="address1" [(ngModel)]="address1" placeholder="Address Line" required>
                                   
                                        <mat-error class="matformfielderror" *ngIf="hasError('address1', 'required')">Address is required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <!-- <div class="col-md-12">
                                <mat-form-field  class="mat-input-width">
                                    <input matInput formControlName="address2" id="address2" [(ngModel)]="address2" placeholder="Address Line 2" >                                    
                                </mat-form-field>
                            </div> -->
                            <div class="col-md-12 fieldlayout">
                                <div class="checkout-form-list">
                                    <mat-form-field appearance="outline">
                                        <input class="matformfield" matInput formControlName="city" id="city" [(ngModel)]="city" placeholder="City/Town/Suburb" required>
                                   
                                        <mat-error class="matformfielderror" *ngIf="hasError('city', 'required')">City/Town/Suburb is required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-12 fieldlayout">
                                <div class="checkout-form-list">
                                    <mat-form-field appearance="outline">
                                        <input class="matformfield" matInput formControlName="zip" id="zip" [(ngModel)]="zip" placeholder="Post Code" (keypress)="numberOnly($event)" required>
                                     
                                        <mat-error class="matformfielderror" *ngIf="hasError('zip', 'required')">Post Code is required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-12 fieldlayout">
                                <div class="checkout-form-list">
                            <!-- <mat-form-field appearance="outline">
                             <input class="matformfield" matInput formControlName="state" id="state" [(ngModel)]="state" placeholder="State" required>
                             <mat-error class="matformfielderror" *ngIf="hasError('state', 'required')">State is required</mat-error>
                            </mat-form-field> -->
                            <div class="form-group category-select" style="width: 300px;">
                            <ngx-select-dropdown (searchChange)="searchChange($event)"formControlName="state" id="state" [(ngModel)]="state" tabindex="0" [multiple]="false" [(ngModel)]="state" [config]="config" [options]="options"></ngx-select-dropdown>
                            </div>
                                  <!-- <mat-form-field class="mat-input-width" appearance="fill">
                                        <mat-label>State</mat-label>
                                        <mat-select formControlName="state" [(ngModel)]="state" id="state" required>
                                            
                                            <mat-option value="WA"> WA</mat-option>
                                            <mat-option value="QLD"> QLD</mat-option>
                                            <mat-option value="NSW"> NSW</mat-option>
                                            <mat-option value="VIC"> VIC</mat-option>
                                            <mat-option value="SA"> SA</mat-option>
                                            <mat-option value="TAS"> TAS</mat-option>
                                            <mat-option value="NT"> NT</mat-option>
                                            <mat-option value="ACT"> ACT</mat-option>
                                            <mat-option value="other"> Other</mat-option>
                                        </mat-select>
                                      
                                        <mat-error *ngIf="hasError('state', 'required')">State is required</mat-error>
                                    </mat-form-field>   -->
                                </div>
                            </div>
                            <div class="col-md-12 fieldlayout">
                                <div class="checkout-form-list">
                                    <label>Country: </label>
                                    <span>{{country}}</span>
                                </div>
                            </div>
                            <div class="col-md-12 fieldlayout">
                                <div class="checkout-form-list">
                                    <mat-form-field  appearance="outline">
                                        <input class="matformfield" class="matformfield" matInput formControlName="email" id="email" [(ngModel)]="email" placeholder="Email Address"  required>
                                        <mat-error class="matformfielderror" *ngIf="hasError('email', 'required')">Email Address is Required</mat-error>
                                        <mat-error class="matformfielderror" *ngIf="hasError('email', 'email')">Email Address is invalid</mat-error>
                                    
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-12 fieldlayout">
                                <div class="">
                                    <mat-form-field  appearance="outline">
                                        <input class="matformfield" matInput formControlName="password" [(ngModel)]="password" id="password" placeholder="Choose Password" [type]="hide ? 'password' : 'text'" required>
                                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </button>
                                        <mat-error class="matformfielderror" *ngIf="userRegisterFormGroup.hasError('error')">Password is required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <div class="checkout-form-list create-acc">
                                    <button  mat-flat-button  class="default-btn" id="custom-login-btnId" color="accent" 
                                    (click)="register()"> Register </button>
                                </div>
                            </div>
                            <div class="col-md-4"></div>
                        </div>
                        
                    </div>
                </form>
            </div>
            <div class="col-lg-2 col-12">&nbsp;</div>            
        </div>
    </div>
</div>
                    

<app-footer-style-one></app-footer-style-one>
