<!-- Start Navbar Area -->
<div
    class="navbar-area navbar-style-two"
    [ngClass]="{'sticky': isSticky}"
>
    <div class="vesax-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-xl navbar-light" [class.active]="classApplied">
                <a class="navbar-brand" routerLink="/" style="width: 47%;">
                    <img src="assets/img/logo.png" alt="logo" >
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse">
                   <!--  <form class="navbar-search-box">
                        <label><i class="flaticon-search"></i></label>
                        <input type="text" class="input-search" placeholder="What are you looking for?">
                    </form> -->
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link">
                                Home
                            </a>
                            
                        </li> 
<!-- 
                        <li class="nav-item">
                            <a routerLink="/about" class="nav-link">
                                About Us
                            </a>
                        
                        </li> -->

                        <li class="nav-item">
                            <a routerLink="/listings" class="nav-link">
                                Listings
                            </a>
                        
                        </li>

                        <li class="nav-item">
                            <a routerLink="/pricing" class="nav-link">
                                Pricing
                            </a>
                        
                        </li>

                        <li class="nav-item">
                            <a routerLink="/contact" class="nav-link">
                                Contact Us
                            </a>
                        
                        </li>
                      
                        <!-- <li class="nav-item">
                            <a href="/blog-grid" class="nav-link">
                                Blog
                            </a>
                            
                        </li> -->
                   
                    </ul>
                    <div class="others-option d-flex align-items-center">
                        <div class="option-item"  *ngIf="!auth.isLoggedIn()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <a routerLink="/login" class="nav-link">
                                <i class="flaticon-user"></i> Login
                           </a>
                        </div>
                        <div class="option-item" *ngIf="auth.isLoggedIn()" >
                        <a routerLink="" (click)="logout()" class="nav-link"><i class='bx bx-log-out'></i> <span>Logout</span></a>
                    </div>
                        <div class="option-item"  *ngIf="!auth.isLoggedIn()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                            <a routerLink="/register" class="nav-link">
                                <i class="flaticon-user"></i> Register
                           </a>
                        </div>
                       <!--  <div class="option-item">
                            <a routerLink="/dashboard-add-listings" class="default-btn">
                                <i class="flaticon-more"></i> Add Listing
                            </a>
                        </div> -->
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Start Login & Register Modal -->
<div
    class="modal fade loginRegisterModal" tabindex="-1" aria-hidden="true"
    *ngIf="isSigninSignupOpen"
>
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="close" (click)="closeSigninSignupPopup()">
                <i class='bx bx-x'></i>
            </button>
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <button 
                        class="nav-link"
                        (click)="switchTab($event, 'tab1')"
                        [ngClass]="{'active': currentTab === 'tab1'}"
                    >
                        Login
                    </button>
                </li>
                <li class="nav-item">
                    <button 
                        class="nav-link"
                        (click)="switchTab($event, 'tab2')"
                        [ngClass]="{'active': currentTab === 'tab2'}"
                    >
                        Register
                    </button>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane fade show active" *ngIf="currentTab === 'tab1'">
                    <div class="vesax-login">
                          
                        <form>
                            <div class="form-group">
                                <input type="text" placeholder="Username or Email" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Password" class="form-control">
                            </div>
                            <button type="submit">Login Now</button>
                        </form>
                       
                    </div>
                </div>
                <div class="tab-pane fade show active" *ngIf="currentTab === 'tab2'">
                    <div class="vesax-register">
                        
                         
                        <form>
                            <div class="form-group">
                                <input type="text" placeholder="Username" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="email" placeholder="Email" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Password" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Confirm Password" class="form-control">
                            </div>
                            <button type="submit">Register Now</button>
                        </form>
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop fade show"></div>
</div>
<!-- End Login & Register Modal -->