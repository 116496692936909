<app-navbar-style-one></app-navbar-style-one>


<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitleContent;" style="background-image: url({{Content.backgroundImage}});">
    <div class="container">
        <div class="page-title-content">
            <h2>{{Content.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{Content.title}}</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->
 

<!--Login Register Area Strat--><br><br><br>
<div class="login-register-area pt-80 pb-95"  >
    <div class="container-fluid pd-shop">
        <div class="row">
            <!--Login Form Start-->
            <div class="col-md-4 col-sm-4">&nbsp;</div> 
            <div class="col-md-4 col-sm-4 bg-white" style="border: 1px solid #bcbcbc;
            box-shadow: 0 1px 5px rgba(0,0,0,.05);border-radius: 6px;padding: 10px 15px 30px 15px;">
                <div class="customer-login-register">
                    <div class="form-login-title">
                        <h2>Login</h2>
                    </div>
                    <div class="">
                        <form *ngIf="userLoginFormGroup" id="contact-form" [formGroup]="userLoginFormGroup" >
                            <div class="contact-form-style mb-20">
                                <mat-form-field  appearance="outline" class="mat-input-width">
                                    <input matInput formControlName="email" id="email" [(ngModel)]="email" placeholder="Email" (keypress)="removeWhiteSpace($event)" (change)="removeWhiteSpaceOnChange()" (focusout)="removeWhiteSpaceOnChange()" required>
                                    
                                    <!-- <mat-error *ngIf="hasError('email', 'email')">Email Address is invalid</mat-error>
                                    <mat-error *ngIf="hasError('email', 'required')">Email Address is Required</mat-error> -->
                                </mat-form-field>                                    
                            </div>
                            <div class="contact-form-style mb-20">
                                <mat-form-field appearance="outline" class="mat-input-width">
                                    <input  matInput formControlName="password" [(ngModel)]="password" id="password" placeholder="Password" [type]="hide ? 'password' : 'text'" required>
                                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                  
                                    <!-- <mat-error *ngIf="hasError('password', 'required')">Password is Required</mat-error> -->
                                </mat-form-field>
                                <span style="color: #F44336;">{{message}}</span>

                                <span *ngIf="is_banned" class="text-center">
                                    <br>
                                    <small> Please wait 5 seconds to be redirected to the <a routerLink="/contact-us"><b>Contact Us</b></a> Page OR
                                    <a routerLink="/contact-us"><b>Contact Us Now</b></a>.</small>
                                </span>
                                
                            </div>
                            
                            <div class="contact-form-style mb-20">

                                <button mat-flat-button  class="default-btn" id="custom-login-btnId" color="accent" 
                                (click)="login()"> {{loginButtonText}} </button>
                               
                                <!-- <button  class="btn btn-ausspecs" title="{{loginButtonText}}" id="cust-cart-btn" color="accent"  
                                (click)="login()">{{loginButtonText}}</button>    -->

                            </div> <br><br>
                            <div class="lost-password">
                                <a routerLink="/forgot-password">Lost your password?</a>
                            </div>
                            <div class="lost-password">
                                <a routerLink="/register">Create New Account?</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-4">&nbsp;</div>
            <!--Login Form End-->            
        </div> 
    </div>
</div>
<!--Login Register Area End-->


<app-footer-style-one></app-footer-style-one>
