<app-navbar-style-one></app-navbar-style-one>

<app-homeone-banner></app-homeone-banner>
<!-- 
 <app-features></app-features> -->

 <section class="category-area pt-100 pb-70">
    <app-category></app-category>
</section>

<app-homeone-listings></app-homeone-listings>  



<app-homeone-destinations></app-homeone-destinations>

<!-- <section class="feedback-area ptb-100">
    <app-feedback-style-one></app-feedback-style-one>
</section> -->

<!-- <section class="how-it-works-area pt-100 pb-70 bg-f9f9f9">
    <app-how-it-works></app-how-it-works>
</section> -->

<!-- <app-homeone-blog></app-homeone-blog> -->



<app-footer-style-one></app-footer-style-one>