import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-destinations',
    templateUrl: './homeone-destinations.component.html',
    styleUrls: ['./homeone-destinations.component.scss'],
    standalone: false
})
export class HomeoneDestinationsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: 'BROWSE BUSINESS BY LOCATION',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.'
        }
    ]
    // singleDestinationsBigBox = [
    //     {
    //         img: 'assets/img/destinations/destinations1.jpg',
    //         subTitle: 'THAILAND',
    //         title: 'Bangkok',
    //         number: '26 Places',
    //         link: 'grid-listings-left-sidebar'
    //     }
    // ]
    singleDestinationsBox = [
        {
            img: 'assets/img/destinations/food1.jpg',
            subTitle: 'Pacinos Restaurant',
            title: 'Rockhampton',
            number: '20 Places',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/food2.jpg',
            subTitle: 'The Waterline Restaurant',
            title: 'Yeppoon',
            number: '30 Places',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/food4.jpg',
            subTitle: 'Rocksalt Bar & Restaurant',
            title: 'Gladstone',
            number: '35 Places',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/food3.jpg',
            subTitle: '21 Doon Restaurant',
            title: 'Blackwater',
            number: '21 Places',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/hair4.jpg',
            subTitle: 'Wonderland Hair Art',
            title: 'Emerald',
            number: '14 Places',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/hair2.jpg',
            subTitle: 'Pure Envy Hair & Beauty',
            title: 'Biloela',
            number: '10 Places',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/hair3.jpg',
            subTitle: 'Cutting Capers Hair Studio',
            title: 'Gracemere',
            number: '14 Places',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/hair6.jpg',
            subTitle: 'Wavelengths for Hair',
            title: 'Emu Park',
            number: '10 Places',
            link: 'grid-listings-left-sidebar'
        }
    ]

}