import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-dashboard-messages',
    templateUrl: './dashboard-messages.component.html',
    styleUrls: ['./dashboard-messages.component.scss'],
    standalone: false
})
export class DashboardMessagesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
