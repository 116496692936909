import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import {UntypedFormBuilder, UntypedFormGroup, Validators,FormControl, FormGroup} from '@angular/forms';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

 
// IMPORT SERVICES
import { AuthenticationService, TokenPayload } from '../../services/auth/authentication.service';
//import { NewproductService } from '../../services/newproduct.service';
//import { CartItemsService } from '../../services/cart-items.service';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage'
import { NavigationService } from '../../services/auth/navigation.service';
import { NgxUiLoaderService, Loader, SPINNER } from 'ngx-ui-loader'; 
import { ToastrService } from 'ngx-toastr';
declare var jquery: any;
declare var $: any;

 

@Component({
    selector: 'app-login',
    // standalone: true,
    // imports: [],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
    standalone: false
})
export class LoginComponent  implements OnInit {

  showSpinner: boolean = true;
  // userLoginFormGroup: UntypedFormGroup;
  hide = true;

  userLoginFormGroup = new FormGroup({
    email: new FormControl(),
    password: new FormControl()
  });

  email:any;
  password:any;

 
  ccount: any;
  details: any;

  previousRoute: string;
  errorEmailMessage = '';
  errorPasswordMessage = '';
  message = '';
  isSetItemsToCart: boolean;
  loginButtonText: string;
  ProcessedCartItems = [];
  newCartItems = [];
  is_banned: boolean;

  constructor(        meta: Meta, title: Title,
    private fb: UntypedFormBuilder,
      private routingState: NavigationService,
    // private cartItemsService:CartItemsService, 
    // private productService:NewproductService, 
     private auth: AuthenticationService,                 
    private router: Router,
    public local: LocalStorageService, 
    public session: SessionStorageService,        
    public toastr: ToastrService,) { }

    public hasError = (controlName: string, errorName: string) =>{
      return this.userLoginFormGroup.controls[controlName].hasError(errorName);
  }
  setUserLoginFormFun()
  {
      this.showSpinner = true;
      this.userLoginFormGroup = this.fb.group({
          email: ['', [Validators.required, Validators.email]],
          password: ['', Validators.required]
      });

      // reset login status
      this.auth.logout();

      // get return url from route parameters or default to '/'
      this.previousRoute = this.routingState.getPreviousUrl();
      this.showSpinner = false; 
  }

  ngOnInit()
  {
      this.loginButtonText = "LOGIN";
      
  }
  
  pageTitleContent = [
      {
          title: 'Login',
          backgroundImage: 'assets/img/page-title/page-title1.jpg'
      }
  ]
 
  aboutImage = [
      {
          img: 'assets/img/about-img.jpg',
          
      }
  ]


  login()
  {
      var regExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      // if(this.userLoginFormGroup.value['email']=="")
      // {
      //     this.errorEmailMessage = "Email Required !";
      // //    $("#email").focus();
      // }
      // else if(!regExp.test(this.userLoginFormGroup.value['email']))
      // {
      //     this.errorEmailMessage = "Invalid Email Address !";
      // //    $("#email").focus();
      // }
      // else if(this.userLoginFormGroup.value['password']=="")
      // {
      //     this.errorEmailMessage = "";
      //     this.errorPasswordMessage = "Password Required !";
      // //    $("#password").focus();
      // }
      // else
      // {
          this.errorPasswordMessage = '';
          this.errorEmailMessage = '';
          this.message = '';
          this.loginButtonText = "LOGGING...";    
          
           
          this.auth.login(this.userLoginFormGroup.value).subscribe(
              (loginResponse) => 
              {   
                  if(loginResponse.status == false)
                  {
                      if(loginResponse.type == 'invalid')
                      {
                          this.loginButtonText = "LOGIN";
                          this.message = loginResponse.message;
                         
                      }
                      
                        
                          this.loginButtonText = "LOGIN";
                          this.message = loginResponse.message;
                          console.log(this.message);
                         

                          setTimeout(() => 
                          {
                              this.router.navigate(['/contact-us'])
                          },
                          5000);
                      
                  }
                  else
                  {
                    // this.toastr.success('You are successfully logged in.', 'Login!');        
                    // this.router.navigateByUrl(this.previousRoute);
                      this.auth.profile().subscribe(
                          data => {
                              this.details = data;
                              // GET PROCESSED CART ITEMS 
                              this.local.set("CUST_ID", this.details['id'], 6, 'h');
                              // login successful so redirect to return url
                              setTimeout(() => 
                              {
                                  this.toastr.success('You are successfully logged in.', 'Login!',{});
                                //  this.router.navigateByUrl(this.previousRoute);
                                this.router.navigate(['add-listings']);   
                              },
                              1500);
                              
                          },
                          err => {
                              // console.error(err);
                          }
                      );
                  }
              },
              err => {
                  this.loginButtonText = "LOGIN";
                  this.message = err.error.text;
                  ;
              }
          );
     // }
  }

      // TRIM WHITESPACE ON TEXT
      removeWhiteSpace(event): boolean 
      {
          const charCode = (event.which) ? event.which : event.keyCode;
          if (charCode == 32) // space key code 
          {
              return false;
          }
          return true;
      }
      
      // REMOVE WHITESPACE ON CHANGE
      removeWhiteSpaceOnChange()
      {
          var thisEmail = this.email
          this.email = thisEmail.split(' ').join('');
      }

}
