import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-destinations',
    templateUrl: './homeone-destinations.component.html',
    styleUrls: ['./homeone-destinations.component.scss']
})
export class HomeoneDestinationsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle = [
        {
            title: 'BROWSE BUSINESS BY LOCATION',
            paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.'
        }
    ]
    // singleDestinationsBigBox = [
    //     {
    //         img: 'assets/img/destinations/destinations1.jpg',
    //         subTitle: 'THAILAND',
    //         title: 'Bangkok',
    //         number: '26 Places',
    //         link: 'grid-listings-left-sidebar'
    //     }
    // ]
    singleDestinationsBox = [
        {
            img: 'assets/img/destinations/rockhampton.png',
            subTitle: 'Pacinos  Restaurant Rockhampton',
            title: 'Rockhampton',
            number: '20 Places',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/rockhampton.png',
            subTitle: 'Boat House Restaurant Rockhampton',
            title: 'Yeppoon',
            number: '30 Places',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/rockhampton.png',
            subTitle: 'Cocoa Brew Restaurant Rockhampton',
            title: 'Gladstone',
            number: '35 Places',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/rockhampton.png',
            subTitle: 'Silk Laser Beauty Rockhampton',
            title: 'Blackwater',
            number: '21 Places',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/rockhampton.png',
            subTitle: 'The Safari Room Hairdresser Rockhampton',
            title: 'Emerald',
            number: '14 Places',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/rockhampton.png',
            subTitle: 'Aspirations Beauty Hairdresser Yeppoon',
            title: 'Biloela',
            number: '10 Places',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/rockhampton.png',
            subTitle: 'Pacinos  Restaurant Rockhampton',
            title: 'Mount Morgan',
            number: '14 Places',
            link: 'grid-listings-left-sidebar'
        },
        {
            img: 'assets/img/destinations/rockhampton.png',
            subTitle: 'Boat House Restaurant Rockhampton',
            title: 'Emu Park',
            number: '10 Places',
            link: 'grid-listings-left-sidebar'
        }
    ]

}