import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { NavigationEnd } from '@angular/router';
import {UntypedFormControl, FormGroupDirective, NgForm, FormBuilder, FormGroup, Validators, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

import { Meta, Title } from '@angular/platform-browser';

import { AuthenticationService, TokenPayload } from '../../services/auth/authentication.service';   
 import { ToastrService } from 'ngx-toastr';
 import { NgxSpinnerService } from "ngx-spinner";
 import { NavigationService } from '../../services/auth/navigation.service';
 import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage'
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-listings-details',
    templateUrl: './listings-details.component.html',
    styleUrls: ['./listings-details.component.scss'],
    standalone: false
})
export class ListingsDetailsComponent implements OnInit {

    madelnum: any;
    headerurl: string;
    listingdata : any;
    reviewdata : any;
    category : any;
    service_name : any;
    description : any;
    image_link : any;
    phone : any;
    email : any;
    website : any;  
    keyword : any;
    city : any;

    formrating : any;
    formname: any;
    formemail: any;
    formmessage: any;
    
    userFormGroup: FormGroup;
    errorMessageForm: any;
    errorStatus=false;
    


    constructor(
        meta: Meta, title: Title,
        private fb: UntypedFormBuilder,
        private auth: AuthenticationService, 
        public local: LocalStorageService, 
        private route: ActivatedRoute, 
        private router: Router, 
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        private routingState: NavigationService,
    ) {
        this.route.paramMap.subscribe(params => 
            {
                this.madelnum = this.route.snapshot.params.model;
                // this.productService.ordernewproduct(this.madelnum).subscribe(
                // data => 
                // {
                //     title.setTitle(data[0]['heading']);
                //     meta.addTags([
                //         // tslint:disable-next-line:max-line-length
                //         { name: 'description', content: data[0]['description'].slice(0, 250) }
                //     ]);
                // });
            });
     }

     listingDetailsFun(madelnum)
     {

        this.userFormGroup = this.fb.group({
            formrating: ['', Validators.required],
            formname: ['', Validators.required],
            formemail: ['', [Validators.required, Validators.email]],
            formmessage: ['', Validators.required],
         
        });

         this.local.set("modelnum", madelnum, 6, 'h');
         this.auth.listingdetails(madelnum).subscribe(
             data => 
             {
                 if (data == 'nodata') 
                 {
                     this.router.navigate(['/'])
                 } 
                 else 
                 {
                    this.listingdata = data;
                    console.log(this.listingdata);
                     this.category = data[0]['service_category'];
                     this.service_name = data[0]['service_name'];
                     this.description = data[0]['description'];
                     this.keyword = data[0]['keyword'];
                     this.city = data[0]['city'];
                     this.email = data[0]['email'];
                     this.website = data[0]['website'];
                     this.phone = data[0]['phone'];
                     this.image_link = data[0]['image_link'];
                   

                 //   this.headerurl = this.headerurl.substr(1);
                //    this.headerurl.toLowerCase();
                    let routelink = ['/listings-details', madelnum, this.headerurl];
                    this.router.navigate(routelink);
                 }
             },
             err => {
                
             }
         );
  
     }

     getreviewFun(madelnum)
     {

       
         this.auth.fetchreviewservice(madelnum).subscribe(
             data => 
             {
                 
                    this.reviewdata = data;
                    console.log(this.reviewdata);
                    
                  
             },
             err => {
                
             }
         );
     }


    ngOnInit(): void {
         this.route.paramMap.subscribe(params => 
            {
                this.madelnum = this.route.snapshot.params.model;
                this.listingDetailsFun(this.madelnum);
                this.getreviewFun(this.madelnum);
              
           });

    }

    onSubmitratingDetails()
    {
        console.log("rating");

       console.log(this.userFormGroup.value);
     
   
        // VALIDATE CLIENT FORM DETAILS
        var regExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        this.userFormGroup.value['formemail'] = this.userFormGroup.value['formemail'].trim();
        if(!this.userFormGroup.value['formrating'])
        {
            this.errorStatus=true;
           
        }
        
        else if(!this.userFormGroup.value['formname'])
        {
            this.errorStatus=true;
           
        }
        else if(!this.userFormGroup.value['formemail'])
        {
            this.errorStatus=true;
            
        }
        else if(!regExp.test(this.userFormGroup.value['formemail']))
        {            
            this.errorStatus=true;
          
            this.errorMessageForm = "Invalid Email Address !";
        }
        else
        {
            if(this.userFormGroup.value['name'] == this.userFormGroup.value['formemail'])
            {
                this.errorStatus=true;
                this.errorMessageForm = "Name and email cannot be the same !";
            }
            else
            {
                this.errorStatus=false;
                this.errorMessageForm = "";
            }
            
        }
      //  if(!this.errorStatus)
      //  { 
            
            this.auth.ratingform(this.userFormGroup.value,this.madelnum).subscribe(
                (data) => {   
                    // console.log("data : ",data)                 
                    if(data['status']==true)
                    {
                        this.toastr.success("Added Review Sucessfully !", 'Done!');
                     //   this.router.navigateByUrl('/thank-you');
                    }
             
                },
                err => {
                    // console.error(err);
            });
    //    }
    }

    singleListingsBox = [
        {
            mainImg: [
                {
                    img: 'assets/img/listings/listings7.jpg'
                }
            ],
            categoryLink: 'single-listings',
            category: 'Restaurant',
            bookmarkLink: 'single-listings',
            location: 'Francisco, USA',
            title: 'The Mad Made Grill',
            price: 'Start From: $121',
            detailsLink: 'single-listings',
            authorImg: 'assets/img/user1.jpg',
            openORclose: 'Open Now',
            extraClass: 'status-open',
            authorName: 'James',
            rating: [
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                }
            ],
            ratingCount: '18'
        },
        {
            mainImg: [
                {
                    img: 'assets/img/listings/listings4.jpg'
                },
                {
                    img: 'assets/img/listings/listings2.jpg'
                }
            ],
            categoryLink: 'single-listings',
            category: 'Hotel',
            bookmarkLink: 'single-listings',
            location: 'Los Angeles, USA',
            title: 'The Beverly Hills Hotel',
            price: 'Start From: $200',
            detailsLink: 'single-listings',
            authorImg: 'assets/img/user2.jpg',
            openORclose: 'Open Now',
            extraClass: 'status-open',
            authorName: 'Sarah',
            rating: [
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bx-star'
                }
            ],
            ratingCount: '10'
        }
    ]

    galleryOptions: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		margin: 30,
        navText: [
            "<i class='flaticon-left-chevron'></i>",
            "<i class='flaticon-right-chevron'></i>"
        ],
		responsive: {
			0: {
				items: 1,
			},
			576: {
				items: 2,
			},
			768: {
				items: 2,
			},
			992: {
				items: 2,
			}
		}
    }
    singleImageBox = [
        {
            img: 'assets/img/gallery/gallery1.jpg'
        },
        {
            img: 'assets/img/gallery/gallery2.jpg'
        },
        {
            img: 'assets/img/gallery/gallery3.jpg'
        },
        {
            img: 'assets/img/gallery/gallery4.jpg'
        },
        {
            img: 'assets/img/gallery/gallery5.jpg'
        }
    ]

    customOptions: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		animateOut: 'fadeOut',
		animateIn: 'fadeIn',
		autoplayHoverPause: true,
		autoplay: true,
		mouseDrag: false,
		items: 1,
        navText: [
            "<i class='flaticon-left-chevron'></i>",
            "<i class='flaticon-right-chevron'></i>"
        ]
    }

    // Tabs
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }

}