import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
    selector: 'app-privacy',
    // standalone: true,
    // imports: [],
    templateUrl: './privacy.component.html',
    styleUrl: './privacy.component.scss',
    standalone: false
})
export class PrivacyComponent {


  constructor(private meta: Meta, title: Title) {
    meta.addTags([
        // tslint:disable-next-line:max-line-length
        { name: 'description', content: 'We look to strengthen  your online presence by creating best privacy protection | 0488701953' }
        ]);
        // title.setTitle('Buy Prescription Glasses Online Latest Fashion frames |  AusSpecs');
        title.setTitle('Best Business Directory| Privacy policy');
 }

  ngOnInit(): void {
  }
  
  pageTitleContent = [
      {
          title: 'Privacy Policy',
          backgroundImage: 'assets/img/about-banner.jpg'
      }
  ]
}
