import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';

import {UntypedFormControl, FormGroupDirective, NgForm, Validators, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

import { Meta, Title } from '@angular/platform-browser';

 import { AuthenticationService, TokenPayload } from '../../services/auth/authentication.service';
 import { ToastrService } from 'ngx-toastr';
 import { NgxSpinnerService } from "ngx-spinner";
 import { NavigationService } from '../../services/auth/navigation.service';
declare var jquery: any;
declare var $: any;

/** Error when invalid control is dirty, touched, or submitted.  */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const controlTouched = !!(control && (control.dirty || control.touched));
        const controlInvalid = !!(control && control.invalid);
        const parentInvalid = !!(control && control.parent && control.parent.invalid && (control.parent.dirty || control.parent.touched));
        return (controlTouched && (controlInvalid || parentInvalid));
    }
}

@Component({
  selector: 'app-register',
  // standalone: true,
  // imports: [],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent implements OnInit {

  showSpinner: boolean = false;

  userRegisterFormGroup: UntypedFormGroup;
  hide = true;
  f_name: any;
  l_name: any;
  email: any;
  password: any;
  address1: any;
  address2: any;
  city: any;
  state: any;
  zip: any;
  country: any;    
  phone: any;
  mobile: any;
  previousRoute: string;

  constructor
  (
      meta: Meta, title: Title,
      private fb: UntypedFormBuilder,
      private auth: AuthenticationService, 
      private router: Router,
      private toastr: ToastrService,
      private spinner: NgxSpinnerService,
      private routingState: NavigationService,
  ) 
  {
      title.setTitle('Register');
      meta.addTags([
          // tslint:disable-next-line:max-line-length
          { name: 'description', content: '' }
      ]);
  }

  pageTitleContent = [
    {
        title: 'Register',
        backgroundImage: 'assets/img/page-title/page-title1.jpg'
    }
]

aboutImage = [
    {
        img: 'assets/img/about-img.jpg',
        
    }
]

setUserRegisterFormFun()
{ 
    this.showSpinner = true;
    this.userRegisterFormGroup = this.fb.group({            
        f_name: ['', Validators.required],
        l_name: ['', Validators.required],
        address1: ['', Validators.required],
        address2: [''],
        city: ['', Validators.required],
        state: [''],
        zip: ['', Validators.required],
        phone: [''],
        mobile: [''],
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
    }, { validator: this.atLeastOnePhoneRequired}); 

    this.country = "Australia";
    this.email = "";
    this.password = "";
    this.address2 = "";
    
    // get return url from route parameters or default to '/'
   // this.previousRoute = this.routingState.getPreviousUrl();
    this.showSpinner = false;
    // get return url from route parameters or default to '/'
    // this.previousRoute = this.routingState.getPreviousUrl();        
}
atLeastOnePhoneRequired(group : UntypedFormGroup) : {[s:string ]: boolean} 
{
    if(group) 
    {
        if(group.controls['phone'].value || group.controls['mobile'].value) {
            return null;
        }            
    }
    return {'error': true};
}
matcher = new MyErrorStateMatcher();
ngOnInit() 
{
  // redirect to home if already logged in
    const isLoggedIn = this.auth.isLoggedIn();
    if(isLoggedIn)
    {
        this.router.navigate(['/']);
    }
    this.setUserRegisterFormFun();
}

public hasError = (controlName: string, errorName: string) =>{
    return this.userRegisterFormGroup.controls[controlName].hasError(errorName);
}
register() 
{ 
 
  //  if (this.userRegisterFormGroup.valid) 
  //  {
        // VALIDATE Customer FORM DETAILS
        var regExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regExp.test(this.userRegisterFormGroup.value['email']))
        {            
       //     $("#email").focus();
        }
        else
        {
            this.userRegisterFormGroup.value['country'] = "Australia";
        //    this.userRegisterFormGroup.value['state'] = "QLD";
          //  this.showSpinner = true;
            this.spinner.show();
        //    console.log("Register");
            this.auth.register(this.userRegisterFormGroup.value).subscribe(
            (data) => 
            {   
 
                if (data.status === true) 
                {    
                 //   this.showSpinner = false;
                 this.spinner.hide();
                 alert("Registered Sucessfully !");
                    this.toastr.success("Registered Sucessfully !", 'Done!');
                    // Registered successful so redirect to return url
                    this.router.navigateByUrl(this.previousRoute);                    
                } 
                else 
                {
                  //  this.showSpinner = false;
                  this.spinner.hide();
                    this.toastr.error(data.message, 'Oops!');
                }
                    
            },
            err => {
            // console.error(err);
            });
        }
        
  //  }
    // else
    // {
    //     const controls = this.userRegisterFormGroup.controls;
    //     console.log("Register not valid");
    //     for (const name in controls) 
    //     {
    //         if (controls[name].invalid) 
    //         {
    //             $('#'+name).focus();
    //         }
    //     }
    // }
}
// ALLOW NUMBER ONLY ON TEXT
numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
}


    // Category Select
   // state: any = [];
    multiSelect: any = [];
    stringArray: any = [];
    objectsArray: any = [];
    resetOption: any;
    config:any = {
        displayKey: "name",
        search: true
    };
    options = ["WA","QLD","NSW","VIC","SA","TAS","NT","ACT","other"];
    searchChange($event) {
        console.log($event);
    }

    
}

