import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-hometwo-banner',
    templateUrl: './hometwo-banner.component.html',
    styleUrls: ['./hometwo-banner.component.scss'],
    standalone: false
})
export class HometwoBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
        this.resetOption = [this.options[0]];
    }

    bannerContent = [
        {
            title: 'Find Nearby',
            paragraph: 'Expolore top-rated attractions, activities and more...',
            popularSearchList: [
                {
                    title: 'Beauty',
                    link: '/'
                },
                {
                    title: 'Child Care',
                    link: '/'
                    
                },
                {
                    title: 'Dentists',
                    link: '/'
                  
                },
                {
                    title: 'Doctors',
                    link: '/'
                  
                },
                {
                    title: 'Electricians',
                    link: '/'
                  
                },
                {
                    title: 'Fitness',
                    link: '/'
                 
                },
                {
                    title: 'Plumber',
                    link: '/'
                 
                }
            ]
        }
    ] 
    bannerImage = [
        {
            img: 'assets/img/banner-img1.png'
        }
    ]

    // Category Select
    singleSelect: any = [];
    multiSelect: any = [];
    stringArray: any = [];
    objectsArray: any = [];
    resetOption: any;
    config:any = {
        displayKey: "name",
        search: true
    };
    options = [
        // Type here your category name
        {
            name: "Beauty",
        },
        {
            name: "Child Care",
        },
        {
            name: "Dentists",
        },
        {
            name: "Doctors",
        },
        {
            name: "Electrician",
        },
        {
            name: "Fitness",
        },
        {
            name: "Plumber",
        }
    ];
    searchChange($event) {
        console.log($event);
    }
    reset() {
        this.resetOption = [];
    }

}