import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-footer-style-one',
    templateUrl: './footer-style-one.component.html',
    styleUrls: ['./footer-style-one.component.scss'],
    standalone: false
})
export class FooterStyleOneComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}