import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
    standalone: false
})
export class ContactComponent implements OnInit {

    constructor(private meta: Meta, title: Title) {
        meta.addTags([
            // tslint:disable-next-line:max-line-length
            { name: 'description', content: 'Find Restaurants,Pet Shops, Medical Centres , hairdressers anywhere in Australia. Let Best Business Directory help you connect with local businesses near you' }
            ]);
            // title.setTitle('Buy Prescription Glasses Online Latest Fashion frames |  AusSpecs');
            title.setTitle('CONTACT BEST BUSINESS DIRECTORY| ROCKHAMPTON|YEPPOON|GLADSTONE ');
     }

    ngOnInit(): void {
    }
 

    pageTitleContent = [
        {
            title: 'Contact Us',
            backgroundImage: 'assets/img/page-title/contact.jpg'
        }
    ]
 
    contactInfoBox = [
        {
            icon: 'bx bx-map',
            title: 'Our Address',
            info: [
                {
                    text: 'Elgin Street Rockhampton, 4700'
                }
            ]
        },
        {
            icon: 'bx bx-phone-call',
            title: 'Email Us',
            info: [
                {
                    // text: '(+44) - 45789 - 5789'
                },
                {
                    text: 'enquiries@bestbusinessdirectory.com.au'
                }
            ]
        },
        {
            icon: 'bx bx-time-five',
            title: 'Hours of Operation',
            info: [
                {
                    text: 'Monday - Friday: 09:00 - 18:00'
                },
                {
                    text: 'Saturday: 09:00 - 17:00'
                }
            ]
        }
    ]

    sectionTitle = [
        {
            title: 'Ready to Get Started?',
            paragraph: 'Your email address will not be published. Required fields are marked *'
        }
    ]

    contactImage = [
        {
            img: 'assets/img/contact.png'
        }
    ]

    submit(form){
        var name = form.name;
        console.log(name);
        
        var email = form.email;
        console.log(email);

        var number = form.number;
        console.log(number);
        
        var message = form.message;
        console.log(message);
    }

}