import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import {UntypedFormControl, FormGroupDirective, NgForm, Validators, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Meta, Title } from '@angular/platform-browser';

import { AuthenticationService, TokenPayload } from '../../../services/auth/authentication.service';   
 import { ToastrService } from 'ngx-toastr';
 import { NgxSpinnerService } from "ngx-spinner";
 import { NavigationService } from '../../../services/auth/navigation.service';

@Component({
    selector: 'app-homeone-banner',
    templateUrl: './homeone-banner.component.html',
    styleUrls: ['./homeone-banner.component.scss'],
    standalone: false
})
export class HomeoneBannerComponent implements OnInit {

    constructor(   meta: Meta, title: Title,
        private fb: UntypedFormBuilder,
        private auth: AuthenticationService, 
        private router: Router,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        private routingState: NavigationService,
        public local: LocalStorageService,) { }

    ngOnInit(): void {
        this.resetOption = [this.options[0]];

        this.auth.getcategory().subscribe(
            data => {
                this.listcategory = data.map((item) => item.category)
                console.log(this.listcategory);
            },
            err => {
                // console.error(err);
            }
        );

    }

    mainBannerContent = [
        {
            title: 'Find Nearby',
            paragraph: 'Expolore top-rated attractions, activities and more...',
            popularSearchList: [
                {
                    title: 'Beauty',
                    link: '/category-listing/builders'
                },
                {
                    title: 'Child Care',
                    link: '/listings'
                    
                },
                {
                    title: 'Dentists',
                    link: '/category-listing/dentists'
                  
                },
                {
                    title: 'Doctors',
                    link: '/category-listing/doctors'
                  
                },
                {
                    title: 'Electricians',
                    link: '/category-listing/electrical'
                  
                },
                {
                    title: 'Fitness',
                    link: '/listings'
                 
                },
                {
                    title: 'Plumbers',
                    link: '/listings'
                 
                }
            ]
        }
    ] 

    // Category Select
    listcategory: any;
    location: any;
    category: any;
    singleSelect: any = [];
    multiSelect: any = [];
    stringArray: any = [];
    objectsArray: any = [];
    resetOption: any;
    config:any = {
        displayKey: "name",
        search: true
    };
    options = [
        // Type here your category name
        {
            name: "Beauty",
        },
        {
            name: "Child Care",
        },
        {
            name: "Dentists",
        },
        {
            name: "Doctors",
        },
        
        {
            name: "Electrician",
        },
        {
            name: "Fitness",
        },
        {
            name: "Plumber",
        }
    ];
    searchChange($event) {
        console.log($event);
    }
    reset() {
        this.resetOption = [];
    }

    listlocation = ["WA","QLD","NSW","VIC","SA","TAS","NT","ACT","other"];
   

    searchlist(category,location) 
{ 

    let routelink = ['/listings'];
    this.router.navigate(routelink);         

    console.log(category);

    console.log(location);
    console.log("list");
}

}