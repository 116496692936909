import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.scss'],
    standalone: false
})
export class StatsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
