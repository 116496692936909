import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';

export interface UserDetails {
    mem_id: number;
    f_name: string;
    l_name: string;
    email: string;
    mem_pass: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    phone: string;
    mobile: string;
}
interface TokenResponse {
    token: string;
}
export interface TokenPayload {
    mem_id: number;
    f_name: string;
    l_name: string;
    email: string;
    mem_pass: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    phone: string;
    mobile: string;

}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  [x: string]: any;
  private token: string;
  baseUrl = environment.baseUrl;
  CUST_ID:number;
     constructor(private http: HttpClient, private router: Router,public local: LocalStorageService) { }
  private saveToken(token: string): void {
      localStorage.setItem('usertoken', token);
      this.token = token;
  }
  private getToken(): string {
      if (!this.token) {
          this.token = localStorage.getItem('usertoken');
      }
      return this.token;
  }

   //   Get customer Details Service
      public getUserDetails(): UserDetails {
        const token = this.getToken();
        let payload;
        if (token) {
            payload = token.split('.')[1];
            payload = window.atob(payload);
            return JSON.parse(payload);
        } else {
            return null;
        }
    }

     // Check customer Service
      public isLoggedIn(): boolean {
        const user = this.getUserDetails();
        if (user) {
           // return user.exp > Date.now() / 1000;
           return true;
        } else {
            return false;
        }
    }
      //  Register New customer Service  
        public register(userRegisterFormDetail): Observable<any> {
          return this.http.post(this.baseUrl + `/users/register`, userRegisterFormDetail);
      }
  
      // customer Login Service
      public login(userDetails): Observable<any> {
          const base = this.http.post(this.baseUrl + `/users/login`, userDetails);
          const request = base.pipe(
              map((data: TokenResponse) => {
                  if (data.token) {
                      this.saveToken(data.token);
                  }
                  return data;
              })
          );
          return request;
      }

          // Client Logout Service
    public logout(): void {
        
        this.token = '';
        window.localStorage.removeItem('usertoken'); 
        this.router.navigateByUrl('/login');             
        
    }

         // Get Customer Profile Details
    public profile(): Observable<any> {
        return this.http.get(this.baseUrl + `/users/client-history`, {
            headers: { Authorization: ` ${this.getToken()}` }
        });
    }

      // Add Listing 
    //   public addlisings(addlisingFormDetail,fileUploadData): Observable<any> {
    //     const CUST_ID = this.local.get("CUST_ID");
    //     return this.http.post(this.baseUrl + `/services/addlisting`, {
    //         params: { 
    //             addlisingFormDetail: addlisingFormDetail, 
    //             fileUploadData: fileUploadData, 
    //             CUST_ID: CUST_ID ,
    //         }
    //     } );
    // }

    public addlisings(addlisingFormDetail,fileUploadData): Observable<any> {
        const CUST_ID = this.local.get("CUST_ID");
        return this.http.post(this.baseUrl + `/services/addlisting`, fileUploadData);
    }

    public getMylistingDetails(): Observable<any> {
        return this.http.get(this.baseUrl + `/users/my-listings`, {
            headers: { Authorization: ` ${this.getToken()}` }
        });
    }

    public getlistingDetails(): Observable<any> {
        return this.http.get(this.baseUrl + `/users/weblistings`);
    }

    // public getcategorylistingDetails(categorylist): Observable<any> {
    //     return this.http.post(this.baseUrl + `/users/categorylistings`, {
    //         params: { categoryname: categorylist }
    //     });
        
    // }

    public getcategorylistingDetails(categorylist): Observable<any> {
        //console.log(model);
        return this.http.post(this.baseUrl + `/users/categorylistings`, {
            params: { categoryname: categorylist }
        });  
    }

    public getlocationlistingDetails(locationlist): Observable<any> {
        //console.log(model);
        return this.http.post(this.baseUrl + `/users/locationlistings`, {
            params: { locationname: locationlist }
        });  
    }

 
    public listingdetails(serviceid): Observable<any> {
        //console.log(model);
        return this.http.post(this.baseUrl + `/users/listingdetails`, {
            params: { service_id: serviceid }
        });  
    }

    public ratingform(userFormGroup,madelnum): Observable<any> {

        console.log(userFormGroup);
        
        return this.http.post(this.baseUrl + `/services/addservicerating`, {
            params: {  userFormGroup: userFormGroup, serviceid: madelnum }
        });  
       
    }


    public getcategory(): Observable<any> {
        return this.http.get(this.baseUrl + `/services/listcategory`);
    }

    public getsubcategory(scategory): Observable<any> {
        
        return this.http.post(this.baseUrl + `/services/listsubcategory`, {
            params: { selected_category: scategory }
        });  
       
    }

    public fetchededitservice(serviceid): Observable<any> {
        
        return this.http.post(this.baseUrl + `/services/geteditservices`, {
            params: { serviceid: serviceid }
        });  
       
    }


    public fetchreviewservice(serviceid): Observable<any> {
        
        return this.http.post(this.baseUrl + `/services/getreviewservices`, {
            params: { serviceid: serviceid }
        });  
       
    }

    public updateservices(editorForm,serviceid): Observable<any> {
        
        return this.http.post(this.baseUrl + `/services/updateservices`, {
            params: {  editorForm: editorForm, serviceid: serviceid }
        });  
       
    }

    public removeservices(serviceid): Observable<any> {
        
        return this.http.post(this.baseUrl + `/services/removeservices`, {
            params: { serviceid: serviceid }
        });  
       
    }


}
