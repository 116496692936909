import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { NavigationEnd } from '@angular/router';
import {UntypedFormControl, FormGroupDirective, NgForm, FormBuilder, FormGroup, Validators, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

import { Meta, Title } from '@angular/platform-browser';

import { AuthenticationService, TokenPayload } from '../../services/auth/authentication.service';   
 import { ToastrService } from 'ngx-toastr';
 import { NgxSpinnerService } from "ngx-spinner";
 import { NavigationService } from '../../services/auth/navigation.service';
 import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage'
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-category-listing',
  templateUrl: './category-listing.component.html',
  styleUrl: './category-listing.component.scss',
  standalone: false
})
export class CategoryListingComponent implements OnInit {

  listDetails: any;
  listDetailMessage: any;
  headerurl : any;
  urldata : any;
  listcategory: any;
  category: any;
  categorylist: any;

  constructor(
    meta: Meta, title: Title,
    private fb: UntypedFormBuilder,
    private auth: AuthenticationService, 
    public local: LocalStorageService, 
    private route: ActivatedRoute, 
    private router: Router, 
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private routingState: NavigationService,
  ) {
    this.route.paramMap.subscribe(params => 
        {
            this.categorylist = this.route.snapshot.params.category;
            
        });
   }


  

  ngOnInit(): void {
      this.resetOption = [this.options[0]];

      this.auth.getcategorylistingDetails(this.categorylist).subscribe(
          // this.auth.getPreviousOrderDetails().subscribe(
              data => {
                  if (data['status'] == false) 
                  {
                      this.spinner.hide();
                      this.listDetailMessage = data['message'];
                  } 
                  else 
                  {
                      this.listDetails = data;
                      console.log(this.listDetails);
                      this.spinner.hide();
                  }
              },
              err => {
                  // console.error(err);
              }
          );

          this.auth.getcategory().subscribe(
        
              data => {
             
                  this.listcategory = data.map((item) => item.category)
              
                  console.log(this.listcategory);
              },
              err => {
                  // console.error(err);
              }
          );

  }

  get_listing(model, heading) 
  {        
      this.local.set("modelnum", model, 6, 'h');
      this.urldata = heading.split(' ');
      this.headerurl = '';
      for (let index = 0; index < this.urldata.length; index++) {
          if (this.headerurl == '') {
              this.urldata[index] = this.urldata[index].toLowerCase();
              this.headerurl = this.urldata[index]
          } else if (this.urldata[index] == '') {

          }
          else {
              this.urldata[index] = this.urldata[index].toLowerCase();
              this.headerurl = this.headerurl + '-' + this.urldata[index]
          }
      }
      console.log(this.headerurl);
       let routelink = ['/listings-details', model, this.headerurl];
      this.router.navigate(routelink);         
  }

  



  pageTitleContent = [
      {
          title: 'Find Popular Places'
      }
  ]

  // Category Select
  singleSelect: any = [];
  multiSelect: any = [];
  stringArray: any = [];
  objectsArray: any = [];
  resetOption: any;
  config:any = {
      displayKey: "name",
      search: true
  };
  options = [
      // Type here your category name
      {
          name: "Restaurants",
      },
      {
          name: "Events",
      },
      {
          name: "Clothing",
      },
      {
          name: "Bank",
      },
      {
          name: "Fitness",
      },
      {
          name: "Bookstore",
      }
  ];
  searchChange($event) {
      console.log($event);
  }
  reset() {
      this.resetOption = [];
  }

  // Ordering Select
  options2 = [
      {
          name: "Recommended",
      },
      {
          name: "Default",
      },
      {
          name: "Popularity",
      },
      {
          name: "Latest",
      },
      {
          name: "Price: low to high",
      },
      {
          name: "Price: high to low",
      }
  ];

  // All Listings
  singleListingsItem = [
      {
          mainImg: 'assets/img/listings/listings9.jpg',
          categoryLink: 'single-listings',
          bookmarkLink: 'single-listings',
          category: 'Restaurant',
          location: 'New York, USA',
          title: 'The Mad Made Grill',
          price: 'Start From: $121',
          detailsLink: 'single-listings',
          authorImg: 'assets/img/user3.jpg',
          authorName: 'James',
          openORclose: 'Open Now',
          extraClass: 'status-open',
          rating: [
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              }
          ],
          ratingCount: '18'
      },
      {
          mainImg: 'assets/img/listings/listings10.jpg',
          categoryLink: 'single-listings',
          bookmarkLink: 'single-listings',
          category: 'Hotel',
          location: 'Los Angeles, USA',
          title: 'The Beverly Hills Hotel',
          price: 'Start From: $200',
          detailsLink: 'single-listings',
          authorImg: 'assets/img/user2.jpg',
          authorName: 'Sarah',
          openORclose: 'Open Now',
          extraClass: 'status-open',
          rating: [
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              }
          ],
          ratingCount: '10'
      },
      {
          mainImg: 'assets/img/listings/listings11.jpg',
          categoryLink: 'single-listings',
          bookmarkLink: 'single-listings',
          category: 'Shopping',
          location: 'Seattle, USA',
          title: 'Blue Water Shopping City',
          price: 'Start From: $500',
          detailsLink: 'single-listings',
          authorImg: 'assets/img/user5.jpg',
          authorName: 'Lina',
          openORclose: 'Open Now',
          extraClass: 'status-open',
          rating: [
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              }
          ],
          ratingCount: '55'
      },
      {
          mainImg: 'assets/img/listings/listings12.jpg',
          categoryLink: 'single-listings',
          bookmarkLink: 'single-listings',
          category: 'Restaurant',
          location: 'New York, USA',
          title: 'Chipotle Mexican Grill',
          price: 'Start From: $150',
          detailsLink: 'single-listings',
          authorImg: 'assets/img/user1.jpg',
          authorName: 'Taylor',
          openORclose: 'Close Now',
          extraClass: 'status-close',
          rating: [
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              }
          ],
          ratingCount: '45'
      },
      {
          mainImg: 'assets/img/listings/listings17.jpg',
          categoryLink: 'single-listings',
          bookmarkLink: 'single-listings',
          category: 'Restaurant',
          location: 'New York, USA',
          title: 'Thai Me Up Restaurant',
          price: 'Start From: $150',
          detailsLink: 'single-listings',
          authorImg: 'assets/img/user2.jpg',
          authorName: 'Sarah',
          openORclose: 'Close Now',
          extraClass: 'status-close',
          rating: [
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              }
          ],
          ratingCount: '45'
      },
      {
          mainImg: 'assets/img/listings/listings16.jpg',
          categoryLink: 'single-listings',
          bookmarkLink: 'single-listings',
          category: 'Shopping',
          location: 'Seattle, USA',
          title: 'Skyview Shopping Complex',
          price: 'Start From: $500',
          detailsLink: 'single-listings',
          authorImg: 'assets/img/user5.jpg',
          authorName: 'Lina',
          openORclose: 'Open Now',
          extraClass: 'status-open',
          rating: [
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              },
              {
                  icon: 'bx bxs-star'
              }
          ],
          ratingCount: '55'
      }
  ]

  verticalListings: number = 1;

}