import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { NavigationEnd } from '@angular/router';
import {UntypedFormControl, FormGroupDirective, NgForm, Validators, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { LocalStorageService, SessionStorageService, LocalStorage, SessionStorage } from 'angular-web-storage';
import { Meta, Title } from '@angular/platform-browser';

import { AuthenticationService, TokenPayload } from '../../../services/auth/authentication.service';   
 import { ToastrService } from 'ngx-toastr';
 import { NgxSpinnerService } from "ngx-spinner";
 import { NavigationService } from '../../../services/auth/navigation.service';

@Component({
    selector: 'app-homeone-listings',
    templateUrl: './homeone-listings.component.html',
    styleUrls: ['./homeone-listings.component.scss'],
    standalone: false
})
export class HomeoneListingsComponent implements OnInit {

    listDetails: any;
    listDetailMessage: any;
    headerurl : any;
    urldata : any;

    constructor(  meta: Meta, title: Title,
        private fb: UntypedFormBuilder,
        private auth: AuthenticationService, 
        private router: Router,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        private routingState: NavigationService,
        public local: LocalStorageService,) { }

    ngOnInit(): void {

        this.auth.getlistingDetails().subscribe(
            // this.auth.getPreviousOrderDetails().subscribe(
                data => {
                    if (data['status'] == false) 
                    {
                        this.spinner.hide();
                        this.listDetailMessage = data['message'];
                    } 
                    else 
                    {
                        this.listDetails = data;
                        console.log(this.listDetails);
                        this.spinner.hide();
                    }
                },
                err => {
                    // console.error(err);
                }
            );
    }

    get_listing(model, heading) 
    {        
        this.local.set("modelnum", model, 6, 'h');
        this.urldata = heading.split(' ');
        this.headerurl = '';
        for (let index = 0; index < this.urldata.length; index++) {
            if (this.headerurl == '') {
                this.urldata[index] = this.urldata[index].toLowerCase();
                this.headerurl = this.urldata[index]
            } else if (this.urldata[index] == '') {

            }
            else {
                this.urldata[index] = this.urldata[index].toLowerCase();
                this.headerurl = this.headerurl + '-' + this.urldata[index]
            }
        }
        console.log(this.headerurl);
         let routelink = ['/listings-details', model, this.headerurl];
        this.router.navigate(routelink);         
    }

    sectionTitle = [
        {
            title: 'Most Visited Listings',
            paragraph: 'Below are popular listings to visit in the Central Queensland Area.'
        }
    ]
    singleListingsBox = [
        {
            mainImg: [
                {
                    img: 'assets/img/listings/listing.jpg'
                }
            ],
            categoryLink: 'Pacinos Restaurant ',
            category: '  ',
            bookmarkLink: '/',
            location: 'Rockhampton',
            title: 'Pacinos  Restaurant',
            price: 'Start From: $150',
            detailsLink: '/',
            authorImg: 'assets/img/user1.jpg',
            openORclose: 'Open Now',
            extraClass: 'status-open',
            
            rating: [
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                }
            ],
            ratingCount: '45'
        },
        {
            mainImg: [
                {
                    img: 'assets/img/listings/listing.jpg'
                },
                {
                    img: 'assets/img/listings/listing.jpg'
                }
            ],
            categoryLink: 'Boat House  Restaurant ',
            category: '  ',
            bookmarkLink: '/',
            location: 'Rockhampton',
            title: 'Boat House Restaurant ',
            price: 'Start From: $150',
            detailsLink: '/',
            authorImg: 'assets/img/user1.jpg',
            openORclose: 'Open Now',
            extraClass: 'status-open',
           
            rating: [
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bx-star'
                }
            ],
            ratingCount: '10'
        },
        {
            mainImg: [
                {
                    img: 'assets/img/listings/listing.jpg'
                }
            ],
            categoryLink: 'Cocoa Brew  Restaurant ',
            category: '  ',
            bookmarkLink: '/',
            location: 'Rockhampton',
            title: 'Cocoa Brew Restaurant',
            price: 'Start From: $150',
            detailsLink: '/',
            authorImg: 'assets/img/user1.jpg',
            openORclose: 'Open Now',
            extraClass: 'status-open',
           
            rating: [
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star-half'
                }
            ],
            ratingCount: '35'
        },
        {
            mainImg: [
                {
                    img: 'assets/img/listings/listing.jpg'
                },
                {
                    img: 'assets/img/listings/listing.jpg'
                }
            ],
            categoryLink: 'Silk Laser Beauty  Restaurant ',
            category: '  ',
            bookmarkLink: '/',
            location: 'Rockhampton',
            title: 'Silk Laser Beauty',
            price: 'Start From: $150',
            detailsLink: '/',
            authorImg: 'assets/img/user1.jpg',
            openORclose: 'Open Now',
            extraClass: 'status-open',
          
            rating: [
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bxs-star'
                },
                {
                    icon: 'bx bx-star'
                },
                {
                    icon: 'bx bx-star'
                }
            ],
            ratingCount: '15'
        }
    ]

    customOptions: OwlOptions = {
        loop: false,
        nav: true,
        dots: true,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        navText: [
            "<i class='flaticon-left-chevron'></i>",
            "<i class='flaticon-right-chevron'></i>"
        ],
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 3,
            }
        }
    }
    customOptions2: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		animateOut: 'fadeOut',
		animateIn: 'fadeIn',
		autoplayHoverPause: true,
		autoplay: true,
		mouseDrag: false,
		items: 1,
        navText: [
            "<i class='flaticon-left-chevron'></i>",
            "<i class='flaticon-right-chevron'></i>"
        ]
    }

}