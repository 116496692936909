<app-navbar-style-one></app-navbar-style-one>

<!-- Start Listings Details Area -->
<section class="listings-details-area pb-70">
    <div class="listings-details-image">
        <img src="assets/img/18686.jpg" alt="image">
        <div class="container">
            <div class="container">
                <div class="listings-details-content">
                    <span class="meta"><i class="flaticon-furniture-and-household"></i> {{category}}</span>
                    <h3>{{service_name}}</h3>
                    <div class="rating d-flex align-items-center">
                        <span class="bx bxs-star checked"></span>
                        <span class="bx bxs-star checked"></span>
                        <span class="bx bxs-star checked"></span>
                        <span class="bx bxs-star checked"></span>
                        <span class="bx bxs-star checked"></span>
                        <span class="rating-count">(45)</span>
                    </div>
                    <ul class="d-flex align-items-center">
                        <li class="phone-number">
                            <a href="#"><i class='bx bx-phone-call'></i>{{phone}}</a>
                        </li>
                        <!-- <li class="time">
                            <i class='bx bx-time-five'></i>
                            <span>Currently Open</span>
                            08:00 AM - 10:00 PM
                        </li> -->
                        <li class="location">
                            <i class='bx bx-map'></i>
                            <span>Location</span>
                            {{city}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <div class="container-fluid">
            <ul class="share-save">
                <li>
                    <div class="share">
                        <i class='bx bx-share-alt'></i> Share
                    </div>
                    <div class="social">
                        <a href="#" target="_blank"><i class='bx bxl-facebook'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-pinterest'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-twitter'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-whatsapp'></i></a>
                    </div>
                </li>
                <li><a routerLink="/single-listings"><i class='bx bx-heart'></i> Save</a></li>
            </ul>
        </div> -->
    </div>
    <div class="listings-details-nav">
        <div class="container">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <span
                        class="nav-link"
                        (click)="switchTab($event, 'tab1')"
                        [ngClass]="{'active': currentTab === 'tab1'}"
                    >
                        Overview
                    </span>
                </li>
                <li class="nav-item">
                    <span
                        class="nav-link"
                        (click)="switchTab($event, 'tab2')"
                        [ngClass]="{'active': currentTab === 'tab2'}"
                    >
                        Gallery
                    </span>
                </li>
                <!-- <li class="nav-item">
                    <span
                        class="nav-link"
                        (click)="switchTab($event, 'tab3')"
                        [ngClass]="{'active': currentTab === 'tab3'}"
                    >
                        Pricing
                    </span>
                </li> -->
                <li class="nav-item">
                    <span
                        class="nav-link"
                        (click)="switchTab($event, 'tab4')"
                        [ngClass]="{'active': currentTab === 'tab4'}"
                    >
                        Review
                    </span>
                </li>
                <!-- <li class="nav-item">
                    <span
                        class="nav-link"
                        (click)="switchTab($event, 'tab5')"
                        [ngClass]="{'active': currentTab === 'tab5'}"
                    >
                        Add Review
                    </span>
                </li> -->
            </ul>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div
                    class="listings-details-desc"
                    *ngIf="currentTab === 'tab1'"
                >
                    <h3>Details</h3>
                    <p> {{description}} </p>
               
                    <h3>Gallery</h3>
                    <img src="assets/bimages/{{image_link}}" alt="">
                    <!-- <div id="gallery">
                        <div class="gallery-slides">
                            <owl-carousel-o [options]="galleryOptions">
                                <ng-template carouselSlide *ngFor="let Image of singleImageBox;">
                                    <div class="single-image-box">
                                        <img [src]="Image.img" alt="image">
                                    </div>
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                    </div> -->
                    <!-- <h3>Pricing</h3>
                    <div id="pricing">
                        
                    </div> -->
                    <h3>Review</h3>
              
                    
                    <div id="review">
                        <div class="listings-review-comments">
                            <div class="user-review">
                                <div class="row m-0">
                                    <div class="col-lg-4 col-md-4 p-0">
                                        <div class="user">
                                            <div class="d-flex">
                                                <img src="assets/img/user1.jpg" alt="image">
                                                <div class="title">
                                                    <h4>James Andy</h4>
                                                    <span>New York, USA</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-8 col-md-8 p-0">
                                        <div class="comments">
                                            <div class="rating">
                                                <span class="bx bxs-star checked"></span>
                                                <span class="bx bxs-star checked"></span>
                                                <span class="bx bxs-star checked"></span>
                                                <span class="bx bxs-star checked"></span>
                                                <span class="bx bxs-star checked"></span>
                                            </div>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo maecenas accumsan lacus vel facilisis.</p>
                                      
                                        </div>
                                    </div>
                                </div>
                            </div>
                     
                        
                        </div>
                    </div>
                    <div id="add-review">
                        <div class="review-form-wrapper">
                            <h3>Add A Review</h3>
                            <p class="comment-notes">Your email address will not be published. Required fields are marked <span>*</span></p>
                            <form >
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="sub-ratings">
                                            <div class="row">
                                                <div class="col-lg-2 col-md-2 col-2 col-sm-2">
                                                    <div class="add-sub-rating">
                                                        <h4>Rating : </h4>
                                                        </div>
                                                    </div>
                                                <div class="col-lg-4 col-md-4 col-6 col-sm-6">
                                                    <div class="add-sub-rating">
                                                        <!-- <h4>Rating</h4> -->
                                                        <div class="cleanliness-rating">
                                                            <input type="radio" id="cleanlinessStar5" name="cleanliness-rating" value="5" /><label for="cleanlinessStar5"></label>
                                                            <input type="radio" id="cleanlinessStar4" name="cleanliness-rating" value="4" /><label for="cleanlinessStar4"></label>
                                                            <input type="radio" id="cleanlinessStar3" name="cleanliness-rating" value="3" /><label for="cleanlinessStar3"></label>
                                                            <input type="radio" id="cleanlinessStar2" name="cleanliness-rating" value="2" /><label for="cleanlinessStar2"></label>
                                                            <input type="radio" id="cleanlinessStar1" name="cleanliness-rating" value="1" /><label for="cleanlinessStar1"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                       
                                             
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" name="formname" id="formname" [(ngModel)]="formname" placeholder="Name *">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <input type="email" class="form-control" name="formemail" id="formemail" [(ngModel)]="formemail"  placeholder="Email *">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <textarea placeholder="Your review" name="formmessage" id="formmessage" [(ngModel)]="formmessage"  class="form-control" cols="30" rows="6"></textarea>
                                        </div>
                                    </div>
                               
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit"  (click)="onSubmitratingDetails()">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- <h3>Other Nearby Services</h3>
                    <div class="row">
                        <div class="col-lg-6 col-md-6" *ngFor="let Content of singleListingsBox;">
                            <div class="single-listings-box">
                                <div class="listings-image">
                                    <div class="listings-image-slides">
                                        <owl-carousel-o [options]="customOptions">
                                            <ng-template carouselSlide *ngFor="let Image of Content.mainImg;">
                                                <div class="single-image">
                                                    <img [src]="Image.img" alt="image">
                                                    <a routerLink="/{{Content.detailsLink}}" class="link-btn"></a>
                                                </div>
                                            </ng-template>
                                        </owl-carousel-o>
                                    </div>
                                    <a routerLink="/{{Content.bookmarkLink}}" class="bookmark-save"><i class="flaticon-heart"></i></a>
                                    <a routerLink="/{{Content.categoryLink}}" class="category"><i class="flaticon-cooking"></i></a>
                                </div>
                                <div class="listings-content">
                                    <div class="author">
                                        <div class="d-flex align-items-center">
                                            <img [src]="Content.authorImg" alt="image">
                                            <span>{{Content.authorName}}</span>
                                        </div>
                                    </div>
                                    <ul class="listings-meta">
                                        <li><a routerLink="/{{Content.categoryLink}}"><i class="flaticon-furniture-and-household"></i> Restaurant</a></li>
                                        <li><i class="flaticon-pin"></i> {{Content.location}}</li>
                                    </ul>
                                    <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                                    <span class="status {{Content.extraClass}}"><i class="flaticon-save"></i> {{Content.openORclose}}</span>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="rating">
                                            <i class='{{Star.icon}}' *ngFor="let Star of Content.rating;"></i>
                                            <span class="count">({{Content.ratingCount}})</span>
                                        </div>
                                        <div class="price">
                                            {{Content.price}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div
                    class="listings-details-desc"
                    *ngIf="currentTab === 'tab2'"
                >
                    <h3>Gallery</h3>
                    <img src="assets/bimages/{{image_link}}" alt="">
                    
                    <!-- <div class="gallery-slides">
                        <owl-carousel-o [options]="galleryOptions">
                            <ng-template carouselSlide *ngFor="let Image of singleImageBox;">
                                <div class="single-image-box">
                                    <img [src]="Image.img" alt="image">
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div> -->
                </div>
                <div
                    class="listings-details-desc"
                    *ngIf="currentTab === 'tab3'"
                >
                    <h3>Pricing</h3>
                    <!-- <ul class="pricing-list">
                        <li>Pizza <span>$15</span></li>
                        <li>Burger <span>$10</span></li>
                        <li>Cool Drink <span>$12</span></li>
                        <li>Fried Rice <span>$08</span></li>
                        <li>Orange Juice <span>$05</span></li>
                    </ul> -->
                </div>
                <div
                    class="listings-details-desc"
                    *ngIf="currentTab === 'tab4'"
                >
                    
                    <div class="listings-review-comments">
                        <div class="user-review">
                            <div class="row m-0">
                                <div class="col-lg-4 col-md-4 p-0">
                                    <div class="user">
                                        <div class="d-flex">
                                            <img src="assets/img/user1.jpg" alt="image">
                                            <div class="title">
                                                <h4>James Andy</h4>
                                                <span>New York, USA</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8 p-0">
                                    <div class="comments">
                                        <div class="rating">
                                            <span class="bx bxs-star checked"></span>
                                            <span class="bx bxs-star checked"></span>
                                            <span class="bx bxs-star checked"></span>
                                            <span class="bx bxs-star checked"></span>
                                            <span class="bx bxs-star checked"></span>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo maecenas accumsan lacus vel facilisis.</p>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="review-form-wrapper">
                            <h3>Add A Review</h3>
                            <p class="comment-notes">Your email address will not be published. Required fields are marked <span>*</span></p>
                            <form>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="sub-ratings">
                                            <div class="row">
                                                <div class="col-lg-2 col-md-2 col-2 col-sm-2">
                                                    <div class="add-sub-rating">
                                                        <h4>Rating : </h4>
                                                         
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-4 col-6 col-sm-6">
                                                    <div class="add-sub-rating">
                                                        
                                                        <div class="accuracy-rating">
                                                            <input type="radio" id="accuracyStar5" name="accuracy-rating" value="5" /><label for="accuracyStar5"></label>
                                                            <input type="radio" id="accuracyStar4" name="accuracy-rating" value="4" /><label for="accuracyStar4"></label>
                                                            <input type="radio" id="accuracyStar3" name="accuracy-rating" value="3" /><label for="accuracyStar3"></label>
                                                            <input type="radio" id="accuracyStar2" name="accuracy-rating" value="2" /><label for="accuracyStar2"></label>
                                                            <input type="radio" id="accuracyStar1" name="accuracy-rating" value="1" /><label for="accuracyStar1"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                 
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Name *">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <input type="email" class="form-control" placeholder="Email *">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <textarea placeholder="Your review" class="form-control" cols="30" rows="6"></textarea>
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                 
                        
                    </div>
                </div>
                <!-- <div
                    class="listings-details-desc"
                    *ngIf="currentTab === 'tab5'"
                >
                
                </div> -->
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="listings-sidebar">
                   
                    <div class="listings-widget listings_contact_details">
                        <h3>Contact Details</h3>
                        <ul>
                            <li><i class='bx bx-globe'></i> <a href="#" target="_blank">{{website}}</a></li>
                            <li><i class='bx bx-phone-call'></i> <a href="tel:+2122791456">{{phone}}</a></li>
                            <!-- <li><i class='bx bx-directions'></i> <a href="#" target="_blank">Get Directions</a></li> -->
                            <li><i class='bx bx-map'></i>{{city}}</li>
                        </ul>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Listings Details Area -->

<app-footer-style-one></app-footer-style-one>